import { Component, OnInit, Injectable } from '@angular/core';
import { finalize, tap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import * as firebase from "firebase/app";
import 'firebase/auth';

@Injectable()
export class HttpInterceptorComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router
  ) {

  }


  count = 0;

  ngOnInit() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.spinner.show('loading', {
      type: 'line-scale',
      size: 'large',
      bdColor: 'rgba(100,149,237, .8)',
      color: 'white',
      fullScreen: true
    });
    this.count++;

    let accessToken = localStorage.getItem("access_token");
    let reqCloned = req;
    if (accessToken != '' && accessToken != null) {
      reqCloned = req.clone({
        headers: req.headers.set("Authorization", "Bearer " + accessToken)
      });      
    } else {
      this.router.navigate(['/login']);
    }
    return next.handle(reqCloned)
      .pipe(tap(
        event => {
          if (event instanceof HttpResponse) {
            if (event.body.status) {
              this.router.navigate(['/login']);
            }
          }
        },
        error => console.log(error)
      ), finalize(() => {
        this.count--;
        if (this.count == 0) { this.spinner.hide('loading'); }
      })
      );
  }
}