export const URL = {
  baseURL: 'https://us-central1-hm-case-mgmt.cloudfunctions.net/',
  serviceURL: 'https://us-central1-hm-case-mgmt.cloudfunctions.net/api/',
};

export let platform = {
  isMobile: true
};

export const environment = {
  production: false,
  config: {
    apiKey: "AIzaSyAUVf3fOHQGn3SDihWqZtj2JBIjEmlkC24",
    authDomain: "hm-case-mgmt.firebaseapp.com",
    databaseURL: "https://hm-case-mgmt.firebaseio.com",
    projectId: "hm-case-mgmt",
    storageBucket: "hm-case-mgmt.appspot.com",
    messagingSenderId: "1027829382441",
    appId: "1:1027829382441:web:8f6e0f11d163c10eb60b38",
    measurementId: "G-Q029D7LQ77"
  }
};