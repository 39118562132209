import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'employee-list',
    loadChildren: () => import('./employee/employee-list/employee-list.module').then( m => m.EmployeeListPageModule)
  },
  {
    path: 'employee-form',
    loadChildren: () => import('./employee/employee-form/employee-form.module').then( m => m.EmployeeFormPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./auth/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./auth/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'intake-list',
    loadChildren: () => import('./intake/intake-list/intake-list.module').then( m => m.IntakeListPageModule)
  },
  {
    path: 'intake-form',
    loadChildren: () => import('./intake/intake-form/intake-form.module').then( m => m.IntakeFormPageModule)
  },
  {
    path: 'master-list',
    loadChildren: () => import('./master/master-list/master-list.module').then( m => m.MasterListPageModule)
  },
  {
    path: 'master-form',
    loadChildren: () => import('./master/master-form/master-form.module').then( m => m.MasterFormPageModule)
  },
  {
    path: 'dashboard-home',
    loadChildren: () => import('./dashboard/dashboard-home/dashboard-home.module').then( m => m.DashboardHomePageModule)
  },
  {
    path: 'settings-home',
    loadChildren: () => import('./settings/settings-home/settings-home.module').then( m => m.SettingsHomePageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
